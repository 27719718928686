
.cm-trip-type-wrap {
  padding-bottom: 15px;
}
.cm-fl-search-form > div + div {
  margin-top: 10px;
}
.cm-radio-field label.cm-pointer {
  position: relative;
}
.cm-fl-search-form .cm-radio-field label + label {
  margin-left: 20px;
}

.cm-fl-search-form .cm-radio-field label span {
  padding-left: 6px;
  font-weight: 600;
  user-select: none;
  color: rgb(79, 111, 143);
}
.cm-radio-field label.cm-pointer input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.cm-radio-field label.cm-pointer input:checked + span {
  background: #8b3eea;
  color: #fff;
}
.cm-radio-field label.cm-pointer span {
  padding: 5px 10px;
  display: inline-block;
  border: 1px solid #8b3eea;
  border-radius: 4px;
}
.cm-fl-search-form .cm-mid-wrap label {
  display: block;
  margin-bottom: 4px;
  font-weight: 600;
  color: rgb(79, 111, 143);
}
.select-type .cm-trav-select .cm-pos-relative, .select-type .cm-class-select .cm-pos-relative{
  border: 1px solid #8b3eea;
  padding: 5px 10px;
  border-radius: 4px;
  margin-left: 10px;
}
.select-type .cm-sb-ul {
  min-width: 180px;
}

.ticket_booking .cm-fl-search-form {
  padding: 20px 30px 0;
}
.ticket_booking .cm-form-field{
  width: 15%;
}
.ticket_booking .cm-form-field.cm-srch-inp {
  width: 30%;
}
.ticket_booking .cm-fl-search-btn button.cm-btn{
  padding: 8px 30px;
}

.select-type span.cm-pointer {
  font-size: 14px;
  color: rgb(79, 111, 143);
}

.cm-trav-wrap-sb-active .cm-sb-ul {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.cm-fl-search-form .cm-form-field.cm-srch-inp input[type="text"] {
  padding-left: 30px;
}

.cm-search-inputs .cm-sb-ul,
.cm-fl-class-sb-active .cm-sb-ul,
.cm-trav-wrap-sb-active .cm-sb-ul {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.cm-trav-wrap ul li button {
  background-color: #8b3eea;
  border: none;
  width: 30px;
  height: 30px;
  font-size: 19px;
  cursor: pointer;
  border-radius: 50%;
  display: block;
  color: #fff;
}

.cm-trav-wrap ul li span {
  display: block;
  width: calc(100% - 70px);
  padding: 0 10px;
  color: var(--secCol) !important;
  text-align: center;
  font-weight: 600;
}
.cm-trav-wrap ul li {
  padding: 13px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-type .cm-fl-class-sb-active, .select-type  .cm-trav-wrap-sb-active{
  background-color: #8b3eea;
}
.select-type .cm-fl-class-sb-active span, .select-type  .cm-trav-wrap-sb-active span{
  color: #fff;
}
.cm-search-inputs ul li,
.cm-fl-class-select ul li {
  padding: 10px 15px;
}

.cm-search-inputs ul li + li,
.cm-fl-class-select ul li + li {
  border-top: 1px solid #f2f2f2;
}
.cm-search-inputs ul li:hover {
  background: #8b3eea;
}
.cm-search-inputs ul li:hover *{
  color: #fff !important;
}
.cm-date-selector i,
.cm-search-inputs .cm-content i {
  position: absolute;
  top: calc(50% - 8px);
  left: 10px;
  z-index: 1;
}

.cm-search-inputs .cm-sb-ul {
  width: 100%;
}

.cm-fl-search-form .cm-bottom-wrap > div {
  flex: initial;
}

.cm-fl-search-form .cm-bottom-wrap .cm-fl-search-btn {
  flex: 1;
}

.cm-fl-search-form .cm-bottom-wrap {
  align-items: center;
}

.cm-datepicker-disabled {
  pointer-events: none;
}

.cm-datepicker-disabled input[type="text"] {
  background-color: #ccc !important;
}

.cm-result-list > li > p > span {
  display: block;
}

.cm-result-list > li > p > span:first-child {
  font-size: 13px;
  color: #666;
}

.cm-result-list > li > span {
  color: #666;
  font-size: 14px;
  font-weight: 500;
  padding-left: 5px;
}

.cm-result-list > li > p > span + span {
  font-weight: 600;
}
.home-banner-search .cm-form-field {
  background: #8b3eea17;
  padding: 15px;
  border-radius: 10px;
  width: 24%;
}
.home-banner-search .cm-form-field input {
  border: none;
  padding: 10px 0 !important;
  font-weight: 600;
  color: #000;
  font-size: 18px;
}
.home-banner-search .cm-form-field input::placeholder{
  opacity: 1;
  color: #000;
  font-weight: 600;
}
.inp-label i {
  font-size: 20px;
  color: #000;
}
.inp-label span {
  font-size: 14px;
  color: #818090;
}
.cm-datepicker-disabled {
  pointer-events: none;
  background: #ccc !important;
  opacity: .4;
}
.home-banner-search .cm-fl-search-btn{
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.cm-fl-search-btn button.cm-btn {
  font-family: 'Montserrat', sans-serif;
  background: #8b3eea;
  color: #fff;
  padding: 12px 30px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  border-radius: 14px;
}
.align-icon{
  position: relative;
}
.align-icon i {
  position: absolute;
  top: 11px;
  left: 23px;
}

@media(max-width: 1200px){
  .ticket_booking .cm-fl-search-form {
      padding: 20px 20px 0;
  }
  .ticket_booking .cm-form-field.cm-srch-inp {
      width: 27%;
  }
  .ticket_booking .cm-form-field{
    width: 18%;
  }
  .cm-fl-search-form .cm-bottom-wrap{
    padding: 0;
  }
}
@media(max-width: 1023px){
  .ticket_booking .cm-fl-search-form {
      padding: 10px 0px 20px;
  }
  .cm-fl-search-form .cm-bottom-wrap{
    padding: 0 15px;
  }
}
@media(max-width: 767px){
  .select-type span.cm-pointer{
    padding: 9px;
  }
  .cm-form-field select, .cm-form-field input, .cm-form-field textarea{
    padding: 10px;
    font-size: 12px;
  }
  .select-type span.cm-pointer {
      padding: 9px 14px;
      font-size: 12px;
  }
  .cm-trav-wrap ul li {
      padding: 8px 10px;
      justify-content: space-between;
  }
  .cm-trav-wrap ul li span {
      width: calc(100% - 58px);
      padding: 0 6px;
  }
  .cm-fl-search-btn button.cm-btn {
      padding: 8px 20px;
  }
  .ticket_booking .cm-radio-field {
      flex-wrap: wrap;
  }
  .ticket_booking .cm-radio-field label.cm-pointer {
      width: 37%;
      margin-bottom: 15px;
  }
  .ticket_booking .cm-radio-field .cm-lr-pad {
      width: 46%;
      padding: 0;
  }
}