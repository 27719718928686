
header {
  width: 100%;
  padding: 15px 0 20px;
  border-top: 2px solid #8b3eea;
  box-shadow: 0 0 20px #c3c7cd;
}
.cm-header-logo {
  width: 260px;
}
.cm-header-logo img{
  width: 100%;
  display: block;
}
.cm-header-op {
  width: calc(100% - 170px);
  justify-content: flex-end;
}

.cm-header-phone{
  position: relative;
}
.cm-header-phone img {
  width: 44px;
  position: absolute;
  left: -52px;
  top: 0px;
}
.cm-header-phone a {
  font-size: 20px;
  font-weight: 800;
  color: #337ab7;
}
.cm-header-phone span {
  font-size: 13px;
  font-weight: 600;
  line-height: .5;
  display: block;
  
}
.cm-header-phone > i {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 14px;
  background-color: #337ab7;
}

.mobile-view{
  display: none;
}

@media(max-width: 600px){
  header {
      padding: 9px 0 13px;
  }
  .cm-header-logo {
      width: 170px;
  }
  .cm-header-phone a {
      font-size: 15px;
  }
  .cm-header-phone img {
      width: 29px;
      left: -38px;
      top: 4px;
  }
  .cm-header-phone span {
      font-size: 10px;
  }
}

