.cm-footer-container {
  padding: 30px 0;
 background-color: rgb(188 177 203 / 21%);
}
.footer-logo img{
  width: 200px;
  margin-bottom: 10px;
}
footer p, footer a, .cm-footer-top .cm-footer-col li address{
  color: #000;
}

.cm-footer-container .cm-footer-top {
  padding-bottom: 10px;
}

.cm-footer-top .cm-footer-logo {
  margin-bottom: 15px;
}

.cm-footer-top .cm-footer-col h3 {
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.cm-footer-top .cm-footer-col li + li {
  margin-top: 10px;
}

.cm-footer-top .cm-footer-col li i {
  padding-right: 5px;
}

.cm-footer-top .cm-footer-col li address {
  font-style: normal;
  display: inline-block;
}

.cm-footer-top .cm-footer-col3 li a {
  display: flex;
  align-items: center;
}

.cm-footer-top .cm-footer-col3 li + li {
  margin-top: 20px;
}
.footer_inquery_area {
  padding-top: 5px;
  border-left: 2px solid #8b3eea;
  margin-top: 25px;
  padding-left: 9px;
}
.footer_inquery_area h5 {
  font-size: 16px;
}
footer .footer_inquery_area a, footer .footer_inquery_area address{
  font-weight: 500;
  font-size: 19px;
    padding-top: 8px;
    transition: all .3s ease-in-out .1s;
    outline: 0!important;
    color: #8b3eea !important;
}
footer .footer_inquery_area a:hover, footer .footer_inquery_area address:hover{
  color: #000 !important;
}
.cm-footer-container .cm-footer-bottom {
  padding: 20px 0;
}

.cm-footer-bottom p {
  margin-top: 5px;
}

.cm-footer-bottom p a {
  padding: 0 15px;
}

.cm-fixed-footer-cta {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  background-color: #8b3eea;
  z-index: 99;
}
.cm-fixed-footer-cta p{
  color: #fff;
}
.cm-fixed-footer-cta a {
  font-size: 15px;
  border-radius: 20px;
  font-weight: 700;
  padding: 5px 17px;
  display: inline-block;
  color: #000;
  background-color: #fff;
}

.cm-fixed-footer-cta a i {
  padding-right: 5px;
}
.cm-fixed-footer-cta p br{
  display: none;
}
.card-box {
  padding-top: 20px;
}
.card-box img {
  background: #fff;
  width: 50px;
  height: 44px;
  object-fit: contain;
  border-radius: 5px;
  margin-right: 9px;
}
footer .cm-footer-col.cm-wd-33:not(:first-child) {
  padding-left: 80px;
}
@media(max-width: 991px){
  footer .cm-footer-col.cm-wd-33:not(:first-child) {
      padding-left: 40px;
  }
}
@media screen and (max-width: 767px) {
  body{
    padding: 0 0 79px;
  }
  .cm-footer-top .cm-footer-col {
    width: 100%;
  }
  .cm-fixed-footer-cta p a{
    margin-top: 5px;
    display: inline-block;
  }
  .cm-fixed-footer-cta p br{
    display: block;
  }

  .cm-footer-top .cm-page-center {
    flex-wrap: wrap;
  }
  .cm-footer-top .cm-footer-col h3 {
      margin-bottom: 8px;
  }
  .cm-footer-top .cm-footer-col + .cm-footer-col {
    margin-top: 25px;
  }
  .cm-footer-bottom .cm-flex-type-2{
    flex-wrap: wrap;
  }
  .cm-footer-top .cm-footer-col li+li {
      margin-top: 5px;
  }
  .footer_inquery_area {
      margin-top: 15px;
  }
  footer .cm-footer-col.cm-wd-33:not(:first-child) {
      padding-left: 0px;
  }
  .footer_inquery_area h5 {
      font-size: 14px;
  }
  footer .footer_inquery_area a, footer .footer_inquery_area address {
      font-size: 16px;
  }
}
@media(max-width: 600px){
  .cm-fixed-footer-cta p {
      font-size: 14px;
  }
}
